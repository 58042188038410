<template>
  <div>
    <v-row>
        <v-col align="center">
        <v-text-field class="centered-input"
                v-model="search"
                append-icon="search"
                label="Search Module..."
                single-line
                hide-details
                style="width: 50%"
              ></v-text-field>
        </v-col>
      </v-row>
    <!-- <v-container fluid> -->
     
      <v-row dense>
          <v-col cols="12" sm="2"  v-for="linkname in filteredSortSearchList" :key="linkname.name" >
          <router-link :to="{ name: linkname.name}" style="text-decoration: none">
            <v-card :elevation="5" id="card-hover" fill-height style=" margin:8px; height:100%">
              <br>
              <center>
                <v-avatar class="avatar-icon" size="30">
                  <v-icon size="30" class="link-icon btn-title">{{linkname.icon}}</v-icon>
                </v-avatar>
                <br/>
              <h5>{{linkname.textval}}</h5></center>
            
            
             <!---  <v-card-actions>
               <v-avatar v-if="linkname.size==0" color="red darken-1" size="20">
                  <span class="white--text" :title="'#'+linkname.size+' Field Added'">{{linkname.size}}</span>
                </v-avatar>
                <v-avatar v-else color="success" size="20">
                  <span class="white--text" :title="'#'+linkname.size+' Field Added'">{{linkname.size}}</span>
                </v-avatar>
                <v-spacer></v-spacer>

                <router-link :to="{ name: linkname.name}" style="text-decoration: none">
                  <v-btn icon size="20">
                    <v-icon
                      :title="'Click here to Add '+linkname.textval"
                      class="add-plus-icon"
                    >mdi-plus-circle</v-icon>
                  </v-btn>
                </router-link>
              </v-card-actions>-->
            </v-card>
            
          </router-link>
          </v-col>
        
      </v-row>
    
  </div>
</template>

<script>
import axios from 'axios'
export default { 
  data() {
    return {
      routerLinkList: [],
       search:'',
    };
  }, // end of data()
  mounted(){
    axios.get("TPO/tpoReportMenu")
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
        this.data = res.data
        this.routerLinkList=res.data.routerLinkList
        //console.log(this.data)  
      }
      else{}
    })
    .catch(error => {
      window.console.log(error)
    })
  },
  computed: {
    filteredSortSearchList(){
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      if (!this.search) return this.routerLinkList.sort(compare);
      
      return this.routerLinkList.filter(tbdata=>{
        return tbdata.name.toLowerCase().indexOf(this.search.toLowerCase())>=0       
      })
    }
  } // end of computed()

};
</script>
<style scoped>
.card-title{
  font-weight: 400;
  color: #9E9E9E;
}
.add-plus-icon{
  color: #616161
}
.avatar-icon {
  border: 1px solid #0000001a;
}
.link-icon {
  color: #bdbdbd;
}

#card-hover {
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
#card-hover:hover {
  background: #ededed;
}
#card-hover:hover .link-icon {
  color: #3f51b5;
}
#card-hover:hover .avatar-icon {
  border-color: #3f51b5;
  background: white;
}
#card-hover:hover .card-title {
  color: #777777; 
}
#card-hover:hover .add-plus-icon {
  border: none;
  border-radius: 100%; 
  color: white;
  background:#3f51b5;
}
.link-icon {
  color: rgb(19, 74, 192);
}
.btn-title{
  text-transform: uppercase;
  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>